import { SubscriptionParams, SubscriptionProps } from '$types/store/endpoints/subscriptions';
import { setParams } from '@utils/setParams';
import api from '@store/api';

const subscriptions = api.injectEndpoints({
	endpoints: builder => ({
		createSubscription: builder.mutation<SubscriptionParams, SubscriptionProps>({
			query: params => {
				return {
					url: '/v1/subscriptions',
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: params,
				};
			},
			invalidatesTags: ['subscriptions'],
		}),
		deleteToken: builder.mutation<{success: number, result?: string, error?: string}, { token: string }>({
			query: param => ({
				url: `/v1/subscriptions/token?${setParams(param)}`,
				method: 'DELETE',
			}),
			invalidatesTags: [{ type: 'subscriptions' }],
		}),
	}),
});

export const { useCreateSubscriptionMutation, useDeleteTokenMutation } = subscriptions;
