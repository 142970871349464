/* eslint-disable camelcase */
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import jwtDecode from 'jwt-decode';

import RightSideImage from '../../assets/images/signin-page.png';
import { useGetLoggedUserMutation } from '@store/endpoints/user';
import { LOGIN_INPUTS, SIGNIN_INPUTS } from '@data/signin';
import { toggleSigninModal } from '@store/reducers/modal';
import InputField from '@components/partials/inputField';
import { requestPermission } from '@utils/firebaseInit';
import { setTitle } from '@store/reducers/context';
import * as endpoints from '@store/endpoints/auth';
import Header from '@components/shared/header';
import { setUser } from '@store/reducers/user';
import Logo from '@images/Logo_plutonn.png';
import styles from './login.module.scss';
import Loader from '@components/loaders';

function Login() {
	const [passwordType, setPasswordType] = useState('password');
	const [forgotPassword, setForgotPassword] = useState(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [user, setCurrUser] = useState<any>({
		firstName: '',
		lastName: '',
		email: '',
		phoneNumber: '',
		password: '',
		googleId: '',
	});
	const [login, setLogin] = useState(true);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const setShowModal = () => dispatch(toggleSigninModal());

	const currentUser = useSelector((state: any) => state.user?.user);

	const onChange = (value: string, name: string) => {
		setCurrUser((prev: any) => ({ ...prev, [name]: value }));
	};

	const [signin] = endpoints.useSigninMutation();
	const [signup] = endpoints.useSignupMutation();
	const [loggedUser] = useGetLoggedUserMutation();
	const [googlesignin] = endpoints.useGoogleSigninMutation();
	const [resetPassword] = endpoints.useResetPasswordMutation();

	const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		setLoading(true);
		if (forgotPassword) {
			const loadingToastId = toast.loading('Sending you an email...');
			resetPassword({ email: user.email })
				.unwrap()
				.then(data => {
					toast.update(loadingToastId, {
						render: 'Password reset link sent to your email! ',
						type: toast.TYPE.SUCCESS,
						isLoading: false,
						autoClose: 2500,
					});
					setLoading(false);
				})
				.catch(error => {
					toast.update(loadingToastId, {
						render: error?.data?.error || 'Something went wrong',
						type: toast.TYPE.ERROR,
						isLoading: false,
						autoClose: 2500,
					});
					setLoading(false);
				});
			return;
		}
		setCurrUser((prev: any) => ({ ...prev, googleId: '' }));
		const newUser = { ...user, googleId: '' };
		const loadingToastId = toast.loading('Signing you in...');
		if (login) {
			signin(newUser)
				.unwrap()
				.then(data => {
					setLoading(false);
					toast.update(loadingToastId, {
						render: 'Successfully Signed in! 👌',
						type: toast.TYPE.SUCCESS,
						isLoading: false,
						autoClose: 3000,
					});
					navigate('/');

					// setShowModal(false);
					loggedUser(null)
						.unwrap()
						.then(data => {
							requestPermission({ isIos: false });
							dispatch(setUser(data.result[0]));
						})
						.catch(error => {
							window.location.reload();
						});
				})
				.catch(error => {
					toast.update(loadingToastId, {
						render: error.data.error,
						type: toast.TYPE.ERROR,
						isLoading: false,
						autoClose: 3000,
					});
					setLoading(false);
				});
		} else {
			signup(newUser)
				.unwrap()
				.then(data => {
					setLoading(false);
					toast.update(loadingToastId, {
						render: 'Successfully Signed up! 👌 Kindly Login now',
						type: toast.TYPE.SUCCESS,
						isLoading: false,
						autoClose: 3000,
					});
					setLogin(true);
				})
				.catch(error => {
					toast.update(loadingToastId, {
						render: error.data.error,
						type: toast.TYPE.ERROR,
						isLoading: false,
						autoClose: 3000,
					});
					setLoading(false);
				});
		}
	};

	const handleSuccess = (credentialResponse: any) => {
		const userObject: any = jwtDecode(credentialResponse.credential);
		const user = {
			firstName: userObject.given_name,
			lastName: userObject.family_name,
			email: userObject.email,
			password: '',
			googleId: userObject.sub,
			profileImage: userObject.picture,
			isVerified: true,
		};

		const loadingToastId = toast.loading('Signing you in...');
		googlesignin(user)
			.unwrap()
			.then((data: any) => {
				toast.update(loadingToastId, {
					render: 'Successfully Signed in! 👌',
					type: toast.TYPE.SUCCESS,
					isLoading: false,
					autoClose: 3000,
				});
				navigate('/');
				// setShowModal(false);
				loggedUser(null)
					.unwrap()
					.then(data => {
						requestPermission({ isIos: false });
						dispatch(setUser(data.result[0]));
					})
					.catch(error => {
						console.log({ error });
						window.location.reload();
					});
			})
			.catch((error: any) => {
				toast.update(loadingToastId, {
					render: error.data.error,
					type: toast.TYPE.ERROR,
					isLoading: false,
					autoClose: 3000,
				});
			});
	};

	const handleError = () => {
		toast.error('Something went wrong!');
	};

	function handleGetStartedClick() {
		setLogin(prevLogin => {
			const newLoginState = !prevLogin;
			dispatch(setTitle(newLoginState ? 'Sign in' : 'Sign up'));
			return newLoginState;
		});
	}

	useEffect(() => {
		if (currentUser) {
			navigate('/');
		}
	}, [currentUser, navigate]);

	useEffect(() => {
		dispatch(setTitle('Sign In'));
	}, []);

	return (
		<>
			<header className={styles.header}>
				<Header onClick={() => setShowModal()} />
			</header>
			<div className={styles.card}>
				<aside className={styles.right}>
					<img src={RightSideImage} alt='Plutonn' className={styles.rightSide} />
					<h3>Plutonn App is available on both Android and IOS</h3>
				</aside>
				<aside className={styles.left}>
					<img src={Logo} alt='Plutonn' className={styles.logo} />
					<div className={styles.formCard}>
						{login ? <h2>Sign in to continue</h2> : <h2>Sign up to continue</h2>}
						<form className={`${login ? styles.card__body__login : styles.card__body}`}>
							{(login
								? forgotPassword
									? [
											{
												label: 'Email',
												value: user.email,
												name: 'email',
												placeHolder: 'Email',
												type: 'email',
											},
									  ]
									: LOGIN_INPUTS
								: SIGNIN_INPUTS
							).map((input: any, i) => (
								<InputField
									icon={input.icon}
									key={input.name}
									type={input.name === 'password' ? passwordType : input.type}
									holder={input.label}
									onSubmit={
										input.name === 'password'
											? () => setPasswordType(passwordType === 'password' ? 'text' : 'password')
											: undefined
									}
									empty={true}
									value={user[`${input.name}`]}
									setValue={val => onChange(val, input.name)}
									name={input.name}
									className={styles.inputField}
								/>
							))}
							{login && (
								<button type='button' onClick={() => setForgotPassword(!forgotPassword)} className={styles.forgot}>
									{!forgotPassword ? 'Forgot Password?' : 'Back to Sign in'}
								</button>
							)}
							{loading ? (
								<div className={styles.loadingBtn}>
									<Loader.ImageLoader color={'var(--white)'} width={'25'} />
								</div>
							) : (
								<button type='submit' onClick={handleSubmit} className={styles.btn}>
									{!login ? 'Sign up' : forgotPassword ? 'Send Email' : 'Sign in'}
								</button>
							)}
						</form>
						<p className={styles.getStarted}>
							{login ? "Don't have an account?" : 'Already have an account?'}
							<span onClick={() => handleGetStartedClick()}>{login ? 'Get Started' : 'Sign in'}</span>
						</p>
						<div className={styles.divider}>
							<span>OR</span>
						</div>
						<GoogleLogin onSuccess={handleSuccess} onError={handleError} />
					</div>
				</aside>
			</div>
		</>
	);
}

export default Login;
