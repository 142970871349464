export const SIGNIN_INPUTS = [
	{
		label: 'First Name',
		name: 'firstName',
		placeHolder: 'First Name',
		type: 'text',
	},
	{
		label: 'Last Name',
		name: 'lastName',
		placeHolder: 'Last Name',
		type: 'text',
	},
	{
		label: 'Email',
		name: 'email',
		placeHolder: 'Email',
		type: 'email',
	},
	{
		label: 'Phone Number',
		name: 'phoneNumber',
		placeHolder: 'Phone Number',
		type: 'number',
	},
	{
		label: 'Password',
		name: 'password',
		placeHolder: 'Password',
		type: 'password',
		icon: 'ph:eye',
	},
];

export const LOGIN_INPUTS = [
	{
		label: 'Email',
		name: 'email',
		placeHolder: 'Email',
		type: 'email',
		icon: '',
	},
	{
		label: 'Password',
		name: 'password',
		placeHolder: 'Password',
		type: 'password',
		icon: 'ph:eye',
	},
];

export const userData = {
	firstName: '',
	lastName: '',
	email: '',
	phoneNumber: '',
	password: '',
	googleId: '',
};
