function formatDate(inputDate: any): string {
	const day = inputDate.getDate();
	const month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(inputDate);
	const year = inputDate.getFullYear().toString().slice(-2);
	const formattedDate = `${day} ${month}'${year}`;

	return formattedDate;
}

const timeDifference = (value: string | number | Date) => {
	if (!value) return '';
	const date = new Date(value);
	const now = new Date();

	const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);
	const minutes = Math.floor(seconds / 60);
	const hours = Math.floor(minutes / 60);
	const days = Math.floor(hours / 24);

	const years = Math.floor(days / 365);
	//taking year difference into account for months
	let months = (now.getFullYear() - date.getFullYear()) * 12 + (now.getMonth() - date.getMonth());
	if (months < 0) months = 0;

	if (years > 0) return `${years}yrs ago`;
	if (months > 0) return `${months}mo ago`;

	if (seconds < 60) return `${seconds}s ago`;
	if (minutes < 60) return `${minutes}m ago`;
	if (hours < 24) return `${hours}h ago`;
	if (days < 7) return `${days}d ago`;

	return formatDate(date);
};

export const getDaysRemainingUntil = (date: string) => {
	const currDate = new Date();
	const expiryDate = new Date(date);
	const differenceMs = expiryDate.getTime() - currDate.getTime(); // no need of abs if currDate - expired > 0 it means it is expired
	if (differenceMs < 0) return 0;
	// Convert milliseconds to days
	const differenceDays = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));
	return differenceDays;
};

export default timeDifference;
