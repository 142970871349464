import { useNavigate, useLocation } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '@iconify/react';

import { useGetUnreadNotificationsQuery } from '@store/endpoints/notification';
import SocketContext from '@store/socket/Context';
import Image from '@components/partials/Avatar';
import Logo from '@icons/Plutonn_logo_192.png';
import { RootState } from '@store/store';
import styles from './header.module.scss';

const Header = ({ onClick }: { onClick?: () => void }) => {
	const { data: notif, refetch } = useGetUnreadNotificationsQuery();
	const user = useSelector((state: RootState) => state.user.user);
	const title = useSelector((state: RootState) => state.context.title);
	const [lastPathname, setLastPathname] = useState<string | null>(null);
	const { socket } = useContext(SocketContext).SocketState;

	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const handleNotification = () => {
		navigate('/notification');
	};

	const handleProfile = () => {
		if (user?.username) navigate(`/profile/${user?.username}`);
		else navigate('/login');
	};

	useEffect(() => {
		socket?.on('getNotifications', () => {
			refetch();
		});
	}, [socket]);

	useEffect(() => {
		if (lastPathname === '/notification' && location.pathname !== '/notification') {
			refetch();
		}
		setLastPathname(location.pathname);
	}, [location.pathname, lastPathname, refetch]);

	return (
		<>
			<div className={styles.logo} onClick={() => navigate('/')}>
				<img src={Logo} alt='Plutonn' />
				<h2 className={styles['logo-text']}>plutonn</h2>
			</div>
			<div className={styles.mainContainer}>
				<h1 className={styles.heading}>{title}</h1>
				<div className={styles.personal}>
					{user?.email && (
						<>
							<button onClick={handleNotification}>
								<Icon icon='mdi:bell-outline' width={28} height={28} className={styles.notification} />
								<span className={styles.count}>
									{notif ? (notif.unreadNotifications > 10 ? '9+' : notif?.unreadNotifications) : 0}
								</span>
							</button>
						</>
					)}
					<button className={styles.avatar} onClick={handleProfile}>
						<Image
							src={user?.profile && user?.profile !== '' ? user.profile : 'none'}
							alt={user?.name || 'profile'}
							loader='md'
							errStyle={{ fontSize: '2.3rem' }}
						/>
					</button>
				</div>
			</div>
		</>
	);
};

export default Header;
