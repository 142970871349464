import ReactDOM from 'react-dom';
import styles from './modal.module.scss';
import React, { useEffect } from 'react';
import { Icon } from '@iconify/react';

interface ModalProps {
	children?: React.ReactNode;
	showModal: boolean;
	heading?: string;
	className?: string;
	overlayClassName?: string;
	placement?: string;
	footer?: React.ReactNode;
	iconColor?: string;
	showHeader?: boolean;
	disableCloseIcon?: boolean;
	closeOnClickOutside?: boolean;
	setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const Modal = (props: ModalProps) => {
	const {
		children,
		heading,
		className,
		overlayClassName,
		iconColor,
		placement,
		showModal,
		setShowModal,
		footer,
		showHeader = true,
		disableCloseIcon = false,
		closeOnClickOutside = true,
	} = props;

	useEffect(() => {
		if (showModal) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'unset';
		}
	}, [showModal]);

	const handleBackgroundClick = () => {
		if (closeOnClickOutside) {
			setShowModal(false);
		} else {
			setShowModal(false);
		}
	};

	return (
		<>
			{ReactDOM.createPortal(
				showModal ? (
					<div
						className={`${styles.background} ${overlayClassName}`}
						style={placement === 'top' ? { paddingTop: '8vh' } : { alignItems: 'center' }}
						onClick={() => handleBackgroundClick}>
						<div className={`${styles.modalContainer} ${className}`} onClick={e => e.stopPropagation()}>
							{showHeader ? (
								<div className={styles.header}>
									{!disableCloseIcon && (
										<button className={styles.left}>
											<Icon
												icon='iconoir:cancel'
												color={iconColor && iconColor === '' ? 'black' : iconColor}
												width={30}
												height={30}
												onClick={() => setShowModal(false)}
											/>
										</button>
									)}
									<div className={styles.heading}>
										<h2>{heading}</h2>
									</div>
									<div className={styles.right}></div>
								</div>
							) : null}
							{children}
						</div>
						{footer ? <div className={styles.footer}>{footer}</div> : null}
					</div>
				) : null,
				document.getElementById('modal-root') as HTMLElement
			)}
		</>
	);
};

export default Modal;
