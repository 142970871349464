import { Icon } from '@iconify/react';
import React from 'react';

import PopupImage from '../../../assets/images/deviceframes_(2).png';
import { useDeleteTokenMutation } from '@store/endpoints/subscriptions';
import { removeFromLocalStorage } from '@utils/localStorage';
import { useLogoutMutation } from '@store/endpoints/auth';
import { deleteCookie, getCookies } from '@utils/cookie';
import { setReactions } from '@store/reducers/reactions';
import styles from './restrict-student.module.scss';
import { useNavigate } from 'react-router-dom';
import { setUser } from '@store/reducers/user';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

function RestrictStudent() {
	const navigate = useNavigate();

	const [deleteToken] = useDeleteTokenMutation();
	const [logout] = useLogoutMutation();
	const dispatch = useDispatch();

	const handleLogout = () => {
		const token = getCookies('firebase-token');
		if (token && token !== '') deleteToken({ token });
		dispatch(setUser());
		dispatch(setReactions([]));
		toast.promise(logout(), {
			pending: 'Logging out...',
			success: 'Logged out successfully!',
			error: 'Error logging out!',
		});
		removeFromLocalStorage('isCreator');
		deleteCookie('verification-email');
		removeFromLocalStorage('post');
		deleteCookie('firebase-token');
		deleteCookie('login_state');
		navigate('/');
	};

	return (
		<div className={styles.container}>
			<img src={PopupImage} alt='plutonn-app' className={styles.popupImage} />
			<h3>You have received a Community Access!</h3>
			<p>
				Unlock the full experience by installing the Plutonn app! Engage with vibrant community users, explore personalized
				feeds, and stay updated. Available now on Android and iOS.
			</p>
			<div className={styles['appsContainer']}>
				<a href='https://apps.apple.com/in/app/plutonn/id6470260657' target='blank'>
					<Icon icon='ic:baseline-apple' className={styles.downloadIcon} />
					<p>
						{' '}
						<span>Download on the </span> <br />
						App Store
					</p>
				</a>

				<a href='https://play.google.com/store/apps/details?id=com.plutonn.plutonn&hl=en_IN&pli=1' target='blank'>
					<Icon icon='logos:google-play-icon' className={styles.downloadIcon} />
					<p>
						{' '}
						<span>Get it on </span> <br /> Google Play
					</p>
				</a>
			</div>
			<div onClick={handleLogout} className={styles['logout']}>
				Log out
			</div>
		</div>
	);
}

export default RestrictStudent;
