import React from 'react';
import styles from './Logout.module.scss';
import { useDeleteTokenMutation } from '@store/endpoints/subscriptions';
import { useLogoutMutation } from '@store/endpoints/auth';
import { toast } from 'react-toastify';

import { removeFromLocalStorage } from '@utils/localStorage';
import { deleteCookie, getCookies } from 'src/utils/cookie';
import { setReactions } from '@store/reducers/reactions';
import { setUser } from '@store/reducers/user';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

interface LogoutProps {
	setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const Logout = ({ setShowModal }: LogoutProps) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [deleteToken] = useDeleteTokenMutation();
	const [logout] = useLogoutMutation();

	const handleLogout = () => {
		const token = getCookies('firebase-token');
		if (token && token !== '') deleteToken({ token });
		dispatch(setUser());
		dispatch(setReactions([]));
		toast.promise(logout(), {
			pending: 'Logging out...',
			success: 'Logged out successfully!',
			error: 'Error logging out!',
		});
		removeFromLocalStorage('isCreator');
		deleteCookie('verification-email');
		localStorage.removeItem('post');
		deleteCookie('firebase-token');
		deleteCookie('login_state');
		navigate('/');
	};
	return (
		<div className={styles.modal}>
			<div className={styles.container}>
				<h1>Are you sure you want to Logout?</h1>
				<div className={styles.buttons}>
					<button className={styles.cancelBtn} onClick={() => setShowModal(false)}>
						Cancel
					</button>

					<button className={styles.confirmBtn} onClick={handleLogout}>
						Confirm
					</button>
				</div>
			</div>
		</div>
	);
};

export default Logout;
